package com.y9vad9.pages.de.other

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/other/username-pronunciation")
@Composable
fun Username_pronunciationPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/other/username_pronunciation.md", mapOf("title" to listOf("Pronunciation")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("aussprache") }) {
                org.jetbrains.compose.web.dom.Text("Aussprache")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Mein Benutzername, y9vad9, weckt oft Neugier, also lass mich das für dich erklären. Er wird als \"Я Вадя\" (Ya Vadya) ausgesprochen, was ein spielerischer Hinweis auf meinen Namen, Vadym, ist und seine Wurzeln im ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://de.wikipedia.org/wiki/Leetspeak") {
                    org.jetbrains.compose.web.dom.Text("LEET")
                }
                org.jetbrains.compose.web.dom.Text(" spricht.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die Inspiration kam von einem meiner Freunde – ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://y9san9.me") {
                    org.jetbrains.compose.web.dom.Text("y9san9")
                }
                org.jetbrains.compose.web.dom.Text(", als ich einen einzigartigen Benutzernamen kreieren wollte, der auf allen sozialen Plattformen nicht verwendet wird.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Details:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Das y und 9 zusammen klingen wie \"Я\" (ya) im Russischen/Ukrainischen, was \"Ich\" oder \"Mich\" bedeutet.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Das \"vad\" kommt direkt von meinem Namen, Vadym.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Die 9 am Ende ist eine stilisierte Art, das Ganze abzuschließen, um die \"9\" am Anfang für Symmetrie zu spiegeln.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Also bedeutet y9vad9 \"Ich bin Vadya\" (eine informelle, verkleinerte Form von Vadym). Die resultierende Aussprache ist 'ja-wad-ja'.")
            }
        }
    }
}
