package com.y9vad9.pages.de.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/about-me/interests")
@Composable
fun InterestsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/about-me/interests.md", mapOf("title" to listOf("Meine Interessen")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("meine-interessen") }) {
                org.jetbrains.compose.web.dom.Text("Meine Interessen")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Diese Seite ist nicht für diejenigen gedacht, die mich als Spezialisten kennenlernen möchten, sondern als Person. Hier möchte ich interessante Themen festhalten, die mich neben meiner größten Leidenschaft, der Programmierung, interessieren und über die ich gerne spreche oder lerne.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("geschichte") }) {
                org.jetbrains.compose.web.dom.Text("Geschichte")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ich liebe es, die Vergangenheit zu erkunden. Auch wenn ich kein Historiker bin, fasziniert mich, wie Menschen gelebt, gedacht und die Welt geformt haben, die wir heute kennen.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("psychologie") }) {
                org.jetbrains.compose.web.dom.Text("Psychologie")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Menschen faszinieren mich – ihre Gedanken, Emotionen und Entscheidungsprozesse. Ich interessiere mich besonders für Konzepte wie den Bestätigungsfehler (Confirmation Bias), der meine Denkweise und Interaktionen mit anderen stark beeinflusst hat.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("musik") }) {
                org.jetbrains.compose.web.dom.Text("Musik")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ich höre fast die ganze Zeit Musik und kann ehrlich gesagt nicht anders. Ich höre fast alle Genres, aber momentan bleibe ich hauptsächlich beim Rock. ;)")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("gaming") }) {
                org.jetbrains.compose.web.dom.Text("Gaming")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Auch wenn ich heutzutage kein aktiver Spieler mehr bin und nur in meiner Freizeit einige Mobile Games spiele, verfolge ich die Spieleindustrie weiterhin mit Interesse. Eines Tages möchte ich mein eigenes 2D-Spiel entwickeln – erste Versuche habe ich bereits mit ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://godotengine.org/") {
                    org.jetbrains.compose.web.dom.Text("Godot")
                }
                org.jetbrains.compose.web.dom.Text(" und ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://korge.org") {
                    org.jetbrains.compose.web.dom.Text("Korge")
                }
                org.jetbrains.compose.web.dom.Text(" unternommen.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Einige Mobile Games, die ich spiele, sind unter anderem Brawl Stars und gelegentlich Genshin Impact. 😄")
            }
        }
    }
}
