package com.y9vad9.pages.de.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/about-me/work-experience")
@Composable
fun Work_experiencePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/about-me/work_experience.md", mapOf("title" to listOf("Work Experience")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Berufserfahrung")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("backend-mit-php-2017-2020") }) {
                org.jetbrains.compose.web.dom.Text("Backend mit PHP (2017–2020)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("PHP betrieb ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", meine erste Erfahrung mit Backend-Entwicklung. Dabei lernte ich Serveroptimierung, Benutzerverwaltung und die Herausforderungen der Skalierung kennen.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android-mit-java-2017-2020") }) {
                org.jetbrains.compose.web.dom.Text("Android mit Java (2017–2020)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java war mein Einstieg in die Android-Entwicklung. Als ich ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" entwickelte, hatte ich mich vom Copy-Pasten von Code-Schnipseln zu durchdachten Architektur-Lösungen weiterentwickelt. Meine bevorzugten Tools damals waren Retrofit, Room und RxJava2.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android-mit-kotlin-2020-heute") }) {
                org.jetbrains.compose.web.dom.Text("Android mit Kotlin (2020–heute)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Der Wechsel zu Kotlin hat meine Art zu programmieren grundlegend verändert. Ich setzte Jetpack Compose ein, als es noch in der Alpha-Phase war, und bin dabei geblieben. Mein aktueller Stack umfasst:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, RSocket, gRPC")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Kotlinx Coroutines & Serialization")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Decompose, SQLDelight")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Gradle, Mockk, JUnit")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://developer.android.com/compose") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/airbnb/epoxy") {
                        org.jetbrains.compose.web.dom.Text("Epoxy")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("ajax-systems-dezember-2022-april-2023") }) {
                org.jetbrains.compose.web.dom.Text("Ajax Systems (Dezember 2022 – April 2023)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Bei Ajax Systems, dem führenden Sicherheitsunternehmen in der Ukraine, arbeitete ich als Android-Entwickler im Core-Team. Meine Highlights:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Verbesserung der Anwendungs-Ladezeit um mehr als 50 % durch Optimierung des Parsings von Client-Server-Update-Ereignissen, was zu einer erheblichen Zufriedenheit bei großen Installateuren und Partnern führte.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Entwicklung von UI-Kernkomponenten vorangetrieben, was durch Refaktorisierung des Codes von Epoxy und View-basierten Implementierungen zu Jetpack Compose eine Leistungssteigerung des Teams um 20 % ermöglichte.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Optimierung einer großen, veralteten Java-Codebasis durch Umstellung auf Kotlin, was eine Reduzierung der Produktionsfehler um 30 % und eine schnellere Implementierung neuer Smart-Device-Linien zur Folge hatte.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Das Unternehmen aufgrund meines Umzugs nach Deutschland verlassen.")
            }
        }
    }
}
