package com.y9vad9.pages.ru.archive.task_manager

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/archive/task_manager/")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/archive/task_manager/index.md", mapOf())) {
        com.y9vad9.components.layouts.MarkdownLayout {
        }
    }
}
