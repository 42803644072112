package com.y9vad9.pages.de.archive.task_manager

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/archive/task_manager")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/archive/task_manager/index.md", mapOf("title" to listOf("Task Manager")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("task-manager") }) {
                org.jetbrains.compose.web.dom.Text("Task-Manager")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Der Task-Manager war eine mobile Anwendung, die Benutzern half, die Startanwendungen, aktiven Prozesse und den RAM-Verbrauch ihres Geräts zu verwalten. Mit seiner benutzerfreundlichen Oberfläche und leistungsstarken Funktionalität bot er eine effiziente Möglichkeit, die Leistung des Geräts zu optimieren.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("funktionen") }) {
                org.jetbrains.compose.web.dom.Text("Funktionen")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Startanwendungsverwaltung")
                    }
                    org.jetbrains.compose.web.dom.Text(": Aktivieren oder deaktivieren von Anwendungen, die beim Hochfahren des Telefons starten.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Prozessverwaltung")
                    }
                    org.jetbrains.compose.web.dom.Text(": Anzeigen und Beenden laufender Prozesse zur Freigabe von Systemressourcen.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("RAM-Bereinigung")
                    }
                    org.jetbrains.compose.web.dom.Text(": Optimierung der Speichernutzung für bessere Leistung.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Benutzerfreundliche Oberfläche")
                    }
                    org.jetbrains.compose.web.dom.Text(": Einfaches und intuitives UI für eine unkomplizierte Navigation.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("erfolge") }) {
                org.jetbrains.compose.web.dom.Text("Erfolge")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("70.000+ Downloads")
                    }
                    org.jetbrains.compose.web.dom.Text(": Die App wurde mehr als 70.000 Mal heruntergeladen.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("7.000 monatlich aktive Benutzer")
                    }
                    org.jetbrains.compose.web.dom.Text(": In ihrer Hochphase hatte die Anwendung 7.000 aktive Benutzer pro Monat.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("screenshots") }) {
                org.jetbrains.compose.web.dom.Text("Screenshots")
            }
            org.jetbrains.compose.web.dom.Table {
                org.jetbrains.compose.web.dom.Thead {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                    }
                }
                org.jetbrains.compose.web.dom.Tbody {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/task-manager/processes.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/task-manager/processes.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/task-manager/startup.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/task-manager/startup.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/task-manager/startup.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/task-manager/startup_management.jpeg", "")
                            }
                        }
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("fazit") }) {
                org.jetbrains.compose.web.dom.Text("Fazit")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Der Task-Manager war ein weit verbreitetes Tool zur mobilen Optimierung, das Benutzern half, ihre Geräte reibungslos am Laufen zu halten. Obwohl das Projekt nicht mehr aktiv ist, bleiben sein Einfluss und Erfolg bemerkenswert.")
            }
        }
    }
}
