package com.y9vad9.pages.de.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/summary")
@Composable
fun SummaryPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/about-me/summary.md", mapOf("title" to listOf("Zusammenfassung")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("zusammenfassung") }) {
                org.jetbrains.compose.web.dom.Text("Zusammenfassung")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Geboren am 26. April 2005 in der pulsierenden Stadt Kiew, Ukraine, bin ich ein Softwareentwickler, der Neugierde in einen Beruf verwandelt hat. Meine Geschichte ist eng mit meiner Liebe zu Code, Community und ständigem Lernen verknüpft. Aber lassen wir das nicht zu lang werden — hier ist alles, was du vielleicht wissen möchtest:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/de/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("Programmiersprachen")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/de/about-me/project-details") {
                        org.jetbrains.compose.web.dom.Text("Projekte")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/de/about-me/work-experience") {
                        org.jetbrains.compose.web.dom.Text("Berufserfahrung")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("/de/about-me/interests") {
                        org.jetbrains.compose.web.dom.Text("Interessen")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("sonstiges") }) {
                org.jetbrains.compose.web.dom.Text("Sonstiges")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Zeitzone")
                    }
                    org.jetbrains.compose.web.dom.Text(": UTC+01:00")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Geburtsdatum")
                    }
                    org.jetbrains.compose.web.dom.Text(": 26.04.2005")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Heimatstadt")
                    }
                    org.jetbrains.compose.web.dom.Text(": München")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Einzigartiger Name")
                    }
                    org.jetbrains.compose.web.dom.Text(": y9vad9 (auf den meisten sozialen Netzwerken unter diesem Namen zu finden). ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/de/other/username-pronunciation") {
                        org.jetbrains.compose.web.dom.Text("Aussprache")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Lebenslauf")
                    }
                    org.jetbrains.compose.web.dom.Text(": ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/docs/Vadym_Yaroshchuk_CV.pdf") {
                        org.jetbrains.compose.web.dom.Text("Englische Version")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Gesamte Jahre in der Entwicklung")
                    }
                    org.jetbrains.compose.web.dom.Text(": 6 Jahre (2 mit Java und PHP, 4+ mit Kotlin; ")
                    com.varabyte.kobweb.silk.components.navigation.Link("/de/programming_languages") {
                        org.jetbrains.compose.web.dom.Text("siehe andere oder mehr")
                    }
                    org.jetbrains.compose.web.dom.Text(")")
                }
            }
        }
    }
}
