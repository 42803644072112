package com.y9vad9.localization

import com.y9vad9.types.Article
import com.y9vad9.types.Project

object RussianStrings : Strings {
    override val code: String = "ru"
    override val homeTitle: String = "Главная"
    override val projectsTitle: String = "Проекты"
    override val librariesTitle: String = "Библиотеки"
    override val publicActivityTitle: String = "Общественная активность"
    override val blogTitle: String = "Блог"
    override val latestPostsTitle: String = "Последние посты"
    override val generalSummaryTitle: String = "Резюмируя"
    override val languagesISpeakTitle: String = "Языки, на которых я говорю"
    override val languagesISpeakDescription: String = """
          • ☕️ Английский (B2+)
          • 🇩🇪 Немецкий (B1)
          • 🇺🇦 Украинский (C2)
          • 🏳️ Русский (C2)
    """.trimIndent()
    override val languageICodeWithTitle: String = "Языки программирования"
    override val languagesICodeWithDescription: String = "• Kotlin\n• Java\n• PHP\n• Python (Питомец)\n• TS/JS (Питомец)"
    override val developmentPlatformsTitle: String = "Платформы разработки"
    override val developmentPlatformsDescription: String = "• Android \n• iOS\n• Web\n• JVM (Desktop, Backend)"
    override val myName: String = "Вадим Ярощук"
    override val myDescription: String = "Я Kotlin-разработчик с 6-летним опытом, в основном из личных проектов. Хотя мой профессиональный опыт ограничен, я получил практические навыки, работая над различными приложениями, с акцентом на Kotlin Multiplatform и разработку клиент-серверных решений. Мне нравится решать сложные задачи, упрощать код и делиться тем, что я узнал, через преподавание и написание статей."
    override val learnMore: String = "Узнать больше"
    override val latestProjectsTitle: String = "Последние проекты"
    override val projects: List<Project> = listOf(
        Project(
            name = "TimeMates",
            description = "Приложение для управления временем и совместной работы. С TimeMates вы можете достичь идеального баланса между работой и личной жизнью, оставаясь продуктивными и эффективными. Функция Time-boxing позволяет легко настроить таймер, соответствующий вашему графику, будь то работа над проектом или отдых. TimeMates — это не просто инструмент для управления временем, это изменение образа жизни.",
            url = "https://github.com/timemates",
            imageUrl = "timemates-icon.png"
        ),
        Project(
            name = "Курс по Kotlin",
            description = "Курс по Kotlin, идеально подходящий для новичков. Доступен на украинском и английском языках, разделен на два потока — один для Gradle, другой для Kotlin.",
            url = "https://github.com/y9vad9/kotlin-course",
            imageUrl = "kotlin-course-icon.png",
            roundImage = false,
        )
    )
    override val libraries: List<Project> = listOf(
        Project(
            name = "Brawl Stars API",
            description = "Эта библиотека предоставляет надежный и типобезопасный способ взаимодействия с официальными API Brawl Stars и Brawlify. Разработана для разработчиков, которым нужно надежно обрабатывать данные, она предлагает типобезопасные взаимозаменяемые типы, предустановленные ограничения и вспомогательные функции для улучшения интеграции и уменьшения ошибок.",
            url = "https://github.com/y9vad9/brawlstars-api",
            roundImage = false,
        ),
        Project(
            name = "rRPC",
            description = "rRPC — это фреймворк, предназначенный для предоставления возможности раскрытия вашего API как RPC-сервисов с использованием RSocket. Он упрощает создание сервисов, похожих на gRPC, из .proto файлов с помощью генерации кода. Фреймворк также предоставляет основные компоненты для сервера и клиента.",
            url = "https://github.com/timemates/rrpc-kotlin",
            roundImage = false,
        ),
        Project(
            name = "rsocket-kotlin-router",
            description = "rsocket-kotlin-router — это настраиваемая библиотека, предназначенная для упрощения маршрутизации в серверных приложениях RSocket на Kotlin. Библиотека предлагает типобезопасный DSL для обработки различных маршрутов, служащий декларативной и упрощенной альтернативой ручной маршрутизации, которая иначе приводила бы к длинным тернарным логикам или исчерпывающим выражениям.",
            url = "https://github.com/y9vad9/rsocket-kotlin-router",
            roundImage = false,
        ),
        Project(
            name = "implier",
            description = "Библиотека Kotlin Symbol Processor для создания изменяемых, неизменяемых объектов, билдов, DSL билдов из интерфейсов и абстрактных классов с полями.",
            url = "https://github.com/y9vad9/implier",
            roundImage = false,
        ),
    )
    override val articles: List<Article> = listOf(
        Article(
            name = "Правильный баланс между DDD, Clean и Hexagonal архитектурами",
            description = "Статья о сочетании и совместной реализации DDD, Clean и Hexagonal архитектур. Только на английском.",
            url = "https://dev.to/y9vad9/digging-deep-to-find-the-right-balance-between-ddd-clean-and-hexagonal-architectures-4dnn",
            previewUrl = "previews/DDD-Hexagonal-Article-Preview.jpg"
        ),
        Article(
            name = "Правильный баланс в стратегии зависимостей Gradle",
            description = "Откройте для себя различные подходы к управлению зависимостями в Gradle. Независимо от того, новичок вы или профессионал, здесь есть что-то для вас. Только на английском.",
            url = "https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl",
            previewUrl = "previews/gradle-dependency-strategy.png",
        ),
        Article(
            name = "Gradle: от новичка до основ",
            description = "Погружение в мир Gradle, где я исследую плагины, зависимости, репозитории, структуры проектов и даже многомодульные проекты (с их причудами!). Независимо от того, новичок ли вы в Gradle или хотите укрепить основы, этот материал для вас! Только на английском.",
            url = "https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf",
            previewUrl = "previews/gradle-for-newbies.png",
        ),
        Article(
            name = "Kotlin: корутины — это не только о конкурентности",
            description = "Откройте для себя все тонкости Kotlin Coroutines, начиная с основ. В этом материале я объясняю, как они работают, а также показываю примеры их применения за пределами конкурентности. Только на английском.",
            url = "https://dev.to/y9vad9/coroutines-are-not-just-about-concurrency-4bfe",
            previewUrl = "previews/coroutines-beyond-concurrency.jpg",
        ),
        Article(
            name = "Extension Oriented Design в Kotlin",
            description = "Откройте для себя потенциал Extension-Oriented Design при написании кода. Узнайте, как функции расширений могут решать задачи доступа к классам, обхода ограничений для inline-функций и упрощения организации кода. Изучите преимущества этого подхода и улучшите свой код уже сегодня. Только на английском.",
            url = "https://dev.to/y9vad9/extension-oriented-design-3d41",
            previewUrl = "previews/EOD-Kotlin.jpg",
        )
    )
    override val checkAll: String = "Посмотреть все"
    override val exploreTitle: String = "Изучить"
}
