package com.y9vad9.pages.de.archive.sketchcode

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/archive/sketchcode")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/archive/sketchcode/index.md", mapOf("title" to listOf("Sketchcode")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("sketchcode") }) {
                org.jetbrains.compose.web.dom.Text("Sketchcode")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Im Jahr 2018 habe ich Sketchcode erstellt, ein soziales Netzwerk für Entwickler, die ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://sketchware-docs.vercel.app/") {
                        org.jetbrains.compose.web.dom.Text("Sketchware")
                    }
                    org.jetbrains.compose.web.dom.Text(" nutzen – einen No-Code-Android-App-Builder. Es ging nicht nur darum, Projekte zu teilen; es wurde zu einem Zentrum für Tutorials, UI-Komponenten und Zusammenarbeit. Auf seinem Höhepunkt unterstützte Sketchcode 3.000 aktive Nutzer mit einem minimalen Budget. Der Betrieb dieser Plattform lehrte mich nicht nur das Programmieren – er brachte mir auch Belastbarkeit und Einfallsreichtum bei.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("screenshots") }) {
                org.jetbrains.compose.web.dom.Text("Screenshots")
            }
            org.jetbrains.compose.web.dom.Table {
                org.jetbrains.compose.web.dom.Thead {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                    }
                }
                org.jetbrains.compose.web.dom.Tbody {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/posts-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/posts-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/comments-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/comments-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/more-page.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/more-page.png", "")
                            }
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/project-manager.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/project-manager.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/tutorials-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/tutorials-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/code-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/code-screen.png", "")
                            }
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/chat-screen.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/chat-screen.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/pub-project-screen.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/pub-project-screen.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/project-market.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/project-market.jpeg", "")
                            }
                        }
                    }
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Hinweis: Leider habe ich nicht viele Screenshots (einschließlich der Änderungen der App), daher stelle ich nur diejenigen zur Verfügung, die ich gefunden habe.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("geschichte") }) {
                org.jetbrains.compose.web.dom.Text("Geschichte")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Diese Seite erzählt die Geschichte von Sketchcode, meinem allerersten Projekt, das als App für ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://sketchware-docs.vercel.app/") {
                    org.jetbrains.compose.web.dom.Text("Sketchware")
                }
                org.jetbrains.compose.web.dom.Text(" begann und mit Sketchware selbst entwickelt wurde. Es war eine Herzensangelegenheit, die weit über die Grenzen der No-Code-Entwicklung hinausging, schließlich in Android Studio und Java überführt wurde und den Beginn meiner Programmierreise markierte.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("erste-schritte-globaler-feed-und-benutzerdefinierte-blöcke") }) {
                org.jetbrains.compose.web.dom.Text("Erste Schritte: Globaler Feed und benutzerdefinierte Blöcke")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die erste Version von Sketchcode führte mehrere Kernfunktionen für die Sketchware-Community ein:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ein globaler Feed, in dem Nutzer Inhalte entdecken und teilen konnten.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Benutzerdefinierte Blöcke, die Sketchware-Nutzer einfach in ihre Projekte integrieren konnten.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Code-Snippets, die fortgeschrittenen Nutzern ermöglichten, rohen Java-Code über einen speziellen Baustein einzufügen.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Diese Funktionen legten das Fundament von Sketchcode und machten es zu einem unverzichtbaren Werkzeug für Sketchware-Nutzer, die ihre Kreativität erweitern wollten.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("zweite-große-version-community-und-tutorials") }) {
                org.jetbrains.compose.web.dom.Text("Zweite große Version: Community und Tutorials")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die zweite große Version brachte die App auf das nächste Level, indem sie es der Community ermöglichte, ihre eigenen Inhalte zu teilen:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Eine Funktion zum Teilen von benutzerdefinierten Blöcken und Snippets, die die Zusammenarbeit erleichterte.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Eine neue Tutorial-Sektion mit Anleitungen, die von mir geschrieben wurden, um den Einstieg zu erleichtern.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ein UI-Builder für Tutorials, sodass Nutzer ihre eigenen Lerninhalte erstellen und teilen konnten.")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Hinweis: Leider habe ich keine Screenshots aus dieser Version, daher stelle ich nur den einen zur Verfügung, den ich gefunden habe.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Diese Version verwandelte Sketchcode in einen kollaborativen Raum, der Lernen und Kreativität förderte.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("dritte-große-version-projekte-und-private-backups") }) {
                org.jetbrains.compose.web.dom.Text("Dritte große Version: Projekte und private Backups")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die dritte große Version führte entscheidende neue Funktionen zur Unterstützung und Präsentation von Nutzerprojekten ein:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Eine neue Funktion, mit der Nutzer ihre Beiträge und Sketchware-Projekte mit der Community teilen konnten.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Eine private Projektfunktion für diejenigen, die eine sichere Sicherung ihrer Arbeit benötigten.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Dieses Update festigte Sketchcode als Zentrum für Sketchware-Nutzer, um sich zu vernetzen, zusammenzuarbeiten und ihre Arbeit zu speichern.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("vierte-große-version-große-ui-änderungen-und-engagement-funktionen") }) {
                org.jetbrains.compose.web.dom.Text("Vierte große Version: Große UI-Änderungen und Engagement-Funktionen")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die vierte große Version konzentrierte sich auf die Verbesserung der Nutzerinteraktion und der Benutzererfahrung:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Eine überarbeitete Benutzeroberfläche, die der App ein modernes Aussehen und eine bessere Bedienbarkeit verlieh.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Verbesserungen am Projektmarkt, einschließlich Kommentare, Likes und anderer sozialer Funktionen.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die Unterstützung der Projektfunktion war eine große Herausforderung. Mit über 60 GB Server-Speicher und einem nicht monetarisierten, frei zugänglichen Ansatz habe ich unzählige Stunden damit verbracht, Dateistrukturen zu optimieren und geteilte Projekte zu komprimieren, um den Dienst reibungslos am Laufen zu halten.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("der-höhepunkt-3-4k-nutzer") }) {
                org.jetbrains.compose.web.dom.Text("Der Höhepunkt: 3–4K Nutzer")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Auf seinem Höhepunkt hatte Sketchcode 3.000–4.000 Nutzer und erzeugte über 10 Millionen Anfragen pro Monat. Es war eine aufregende, anstrengende und unglaublich lohnende Zeit. Ich arbeitete oft bis spät in die Nacht, angetrieben von der Begeisterung, etwas aufzubauen und zu verbessern, das Menschen wirklich gerne nutzten.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("letztes-großes-feature-chatsystem") }) {
                org.jetbrains.compose.web.dom.Text("Letztes großes Feature: Chatsystem")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Das letzte große Feature, das ich eingeführt habe, war eine einfache Chat-Funktionalität mit Unterstützung für Bilder und Sticker. Dies ermöglichte den Nutzern, in Echtzeit zu kommunizieren und stärkte das Gemeinschaftsgefühl innerhalb der App weiter.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("rückblick") }) {
                org.jetbrains.compose.web.dom.Text("Rückblick")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Rückblickend war Sketchcode mehr als nur eine App. Es war mein Einstieg in die Programmierung und ein Projekt, das mich auf eine Weise wachsen ließ, die ich mir nie hätte vorstellen können. Von bescheidenen Anfängen in Sketchware bis hin zur Entwicklung einer robusten, funktionsreichen Plattform bleibt es ein prägendes Kapitel auf meinem Weg als Entwickler.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Lass mich wissen, falls du bestimmte Abschnitte anpassen oder bestimmte Teile der Geschichte besonders hervorheben möchtest!")
            }
        }
    }
}
