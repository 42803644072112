package com.y9vad9.localization

import com.y9vad9.types.Article
import com.y9vad9.types.Project

object GermanStrings : Strings {
    override val code: String = "de"
    override val homeTitle: String = "Startseite"
    override val projectsTitle: String = "Projekte"
    override val librariesTitle: String = "Bibliotheken"
    override val publicActivityTitle: String = "Öffentliche Aktivitäten"
    override val blogTitle: String = "Blog"
    override val latestPostsTitle: String = "Neueste Beiträge"
    override val generalSummaryTitle: String = "Allgemeine Zusammenfassung"
    override val languagesISpeakTitle: String = "Gesprochene Sprachen"
    override val languagesISpeakDescription: String = """
          • ☕️ Englisch (B2+)
          • 🇩🇪 Deutsch (B1)
          • 🇺🇦 Ukrainisch (C2)
          • 🏳️ Russisch (C2)
    """.trimIndent()
    override val languageICodeWithTitle: String = "Programmiersprachen"
    override val languagesICodeWithDescription: String = "• Kotlin\n• Java\n• PHP\n• Python (Hobby)\n• TS/JS (Hobby)"
    override val developmentPlatformsTitle: String = "Entwicklungsplattformen"
    override val developmentPlatformsDescription: String = "• Android \n• iOS\n• Web\n• JVM (Desktop, Backend)"
    override val myName: String = "Vadym Yaroshchuk"
    override val myDescription: String = "Ich bin ein Kotlin-Softwareingenieur mit 6 Jahren Erfahrung, hauptsächlich aus persönlichen Projekten. Obwohl meine berufliche Erfahrung begrenzt ist, habe ich praktische Fähigkeiten durch die Arbeit an verschiedenen Anwendungen erworben, mit dem Fokus auf Kotlin Multiplatform und Client-Server-Entwicklung. Ich genieße es, komplexe Probleme zu lösen, den Code zu vereinfachen und mein Wissen durch Lehren und Schreiben zu teilen."
    override val learnMore: String = "Mehr erfahren"
    override val latestProjectsTitle: String = "Neueste Projekte"
    override val projects: List<Project> = listOf(
        Project(
            name = "TimeMates",
            description = "Die Anwendung für Zeitmanagement und Zusammenarbeit. Mit TimeMates kannst du ein perfektes Gleichgewicht zwischen Arbeit und Leben erreichen, während du produktiv und effizient bleibst. Die Time-Boxing-Funktion ermöglicht es dir, einfach einen Timer einzurichten, der in deinen Zeitplan passt, egal ob du an einem Projekt arbeitest oder eine Pause machst. TimeMates ist nicht nur ein weiteres Zeitmanagement-Tool; es ist eine Veränderung des Lebensstils.",
            url = "https://github.com/timemates",
            imageUrl = "timemates-icon.png"
        ),
        Project(
            name = "Kotlin-Kurs",
            description = "Kotlin-Kurs, der perfekt für absolute Anfänger ist. Er ist sowohl in ukrainischer als auch in englischer Sprache verfügbar und in zwei Tracks unterteilt - einen für Gradle und einen für Kotlin.",
            url = "https://github.com/y9vad9/kotlin-course",
            imageUrl = "kotlin-course-icon.png",
            roundImage = false,
        )
    )
    override val libraries: List<Project> = listOf(
        Project(
            name = "Brawl Stars API",
            description = "Diese Bibliothek bietet eine robuste und typsichere Möglichkeit, mit den offiziellen Brawl Stars- und Brawlify-APIs zu interagieren. Sie wurde für Entwickler entwickelt, die zuverlässige Datenverarbeitung benötigen, und bietet typsichere, wiederverwendbare Typen, vordefinierte Einschränkungen und Hilfsfunktionen, um die Integration zu verbessern und Fehler zu reduzieren.",
            url = "https://github.com/y9vad9/brawlstars-api",
            roundImage = false,
        ),
        Project(
            name = "rRPC",
            description = "rRPC ist ein Framework, das die Möglichkeit bietet, deine API als RPC-Dienste unter Verwendung von RSocket bereitzustellen. Es erleichtert die Erstellung von gRPC-ähnlichen Diensten aus .proto-Dateien durch Codegenerierung. Das Framework stellt auch wesentliche Kernkomponenten sowohl für den Server als auch für den Client bereit.",
            url = "https://github.com/timemates/rrpc-kotlin",
            roundImage = false,
        ),
        Project(
            name = "rsocket-kotlin-router",
            description = "rsocket-kotlin-router ist eine anpassbare Bibliothek, die entwickelt wurde, um das Routing für RSocket-Kotlin-Serveranwendungen zu vereinfachen und zu optimieren. Diese Bibliothek bietet eine typsichere DSL für die Handhabung verschiedener Routen und dient als deklarative vereinfachte Alternative zu manuellem Routing, das sonst zu langwierigen ternären Logiken oder umfangreichen 'when'-Anweisungen führen würde.",
            url = "https://github.com/y9vad9/rsocket-kotlin-router",
            roundImage = false,
        ),
        Project(
            name = "implier",
            description = "Kotlin Symbol Processor-Bibliothek zum Erstellen von Mutable, Immutable, Builders und DSL-Builders aus Schnittstellen und abstrakten Klassen mit Eigenschaften.",
            url = "https://github.com/y9vad9/implier",
            roundImage = false,
        ),
    )
    override val articles: List<Article> = listOf(
        Article(
            name = "Das richtige Gleichgewicht zwischen DDD, Clean und Hexagonalen Architekturen finden",
            description = "Artikel über das Kombinieren und Implementieren von Domain-Driven Design, Clean und Hexagonalen Architekturen.",
            url = "https://dev.to/y9vad9/digging-deep-to-find-the-right-balance-between-ddd-clean-and-hexagonal-architectures-4dnn",
            previewUrl = "previews/DDD-Hexagonal-Article-Preview.jpg"
        ),
        Article(
            name = "Das richtige Gleichgewicht in der Gradle-Abhängigkeitsstrategie finden",
            description = "Entdecke verschiedene Ansätze zur Verwaltung von Abhängigkeiten in Gradle. Egal, ob du ein Anfänger oder ein Profi bist, hier ist für dich etwas dabei.",
            url = "https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl",
            previewUrl = "previews/gradle-dependency-strategy.png",
        ),
        Article(
            name = "Gradle: Vom Anfänger zu soliden Grundlagen",
            description = "Tauche ein in die Welt von Gradle, während ich Plugins, Abhängigkeiten, Repositories, Projektstrukturen und sogar Multi-Modul-Projekte (mit ihren Eigenheiten!) erkunde. Egal, ob du neu bei Gradle bist oder deine Grundlagen festigen möchtest, dieser Artikel ist für dich!",
            url = "https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf",
            previewUrl = "previews/gradle-for-newbies.png",
        ),
        Article(
            name = "Kotlin: Koroutinen sind nicht nur für die Nebenläufigkeit",
            description = "Entdecke die Grundlagen von Kotlin-Koroutinen und tauche tief in ihre Funktionsweise ein. In diesem Artikel erkläre ich die Grundlagen und zeige Beispiele, wie sie über die Nebenläufigkeit hinaus eingesetzt werden können.",
            url = "https://dev.to/y9vad9/coroutines-are-not-just-about-concurrency-4bfe",
            previewUrl = "previews/coroutines-beyond-concurrency.jpg",
        ),
        Article(
            name = "Erweiterungsorientiertes Design in Kotlin",
            description = "Entfalte das Potenzial des Erweiterungsorientierten Designs beim Programmieren. Lerne, wie Erweiterungsfunktionen Lösungen bieten, um auf Klassen zuzugreifen, Einschränkungen bei Inline-Funktionen zu umgehen und den Code effizienter zu organisieren. Entdecke die Vorteile dieses Ansatzes und verbessere deinen Code noch heute.",
            url = "https://dev.to/y9vad9/extension-oriented-design-3d41",
            previewUrl = "previews/EOD-Kotlin.jpg",
        )
    )
    override val checkAll: String = "Alle anzeigen"
    override val exploreTitle: String = "Entdecken"
}
