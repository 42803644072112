package com.y9vad9.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobwebx.markdown.LocalMarkdownContext
import org.jetbrains.compose.web.dom.Div
import com.y9vad9.toSitePalette
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.CSSAutoKeyword
import org.jetbrains.compose.web.css.keywords.auto

val MarkdownStyle = CssStyle {
    base { Modifier.fillMaxSize().maxWidth(100.percent).margin(bottom = 3.cssRem).overflow(Overflow.Auto) }

    cssRule("h1") {
        Modifier
            .fontSize(3.cssRem)
            .fontWeight(FontWeight.ExtraBold)
            .margin(bottom = 2.5.cssRem)
            .lineHeight(1.2) //1.5x doesn't look as good on very large text
    }

    cssRule("h2") {
        Modifier
            .fontSize(2.1.cssRem)
            .fontWeight(FontWeight.Bold)
            .margin(topBottom = 2.cssRem)
    }

    cssRule("h3") {
        Modifier
            .fontSize(1.6.cssRem)
            .fontWeight(400)
            .margin(topBottom = 1.5.cssRem)
    }

    cssRule("h4") {
        Modifier
            .fontSize(1.0.cssRem)
            .fontWeight(FontWeight.Bolder)
            .margin(top = 1.cssRem, bottom = 0.5.cssRem)

    }

    cssRule("img") {
        Modifier
            .maxWidth(100.percent)  // ensures the image never exceeds its container’s width
            .height(auto)         // maintains aspect ratio
            .display(DisplayStyle.Block) // removes extra bottom spacing from inline images
    }

    cssRule("ul") {
        Modifier.fillMaxWidth().overflowWrap(OverflowWrap.BreakWord)
    }

    cssRule(" :is(li,ol,ul)") {
        Modifier.margin(bottom = 0.25.cssRem)
    }

    cssRule("code") {
        Modifier
            .color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.8f))
            .fontWeight(FontWeight.Bolder)
    }

    cssRule("pre") {
        Modifier
            .margin(top = 0.5.cssRem, bottom = 2.cssRem)
            .fillMaxWidth()
    }
    cssRule("pre > code") {
        Modifier
            .display(DisplayStyle.Block)
            .fillMaxWidth()
            .backgroundColor(colorMode.toSitePalette().nearBackground)
            .border(1.px, LineStyle.Solid, colorMode.toPalette().color)
            .borderRadius(0.25.cssRem)
            .padding(0.5.cssRem)
            .fontSize(1.cssRem)
            .overflow { x(Overflow.Auto) }
    }

    cssRule("table") {
        Modifier
            .maxWidth(100.percent)
            .width(100.percent)
            .borderCollapse(BorderCollapse.Collapse)
            .styleModifier {
                property("table-layout", "fixed") // Allows columns to adjust dynamically
            }
    }

    cssRule("th") {
        Modifier.display(DisplayStyle.Block)
    }

    cssRule("tr") {
        Modifier.styleModifier {
            property("display", "table-row") // Ensures rows behave correctly
        }
    }

    cssRule("td") {
        Modifier
            .textAlign(TextAlign.Center)
            .styleModifier {
                property("width", "auto") // Each cell auto-adjusts based on content
            }
    }
}

@Composable
fun MarkdownLayout(content: @Composable () -> Unit) {
    PageLayout(LocalMarkdownContext.current!!.frontMatter["title"]!!.first()) {
        Div(MarkdownStyle.toAttrs()) {
            content()
        }
    }
}