package com.y9vad9.pages.de.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/programming_languages")
@Composable
fun Programming_languagesPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/about-me/programming_languages.md", mapOf("title" to listOf("Programmiersprachen")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("") }) {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Programmiersprachen")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("kotlin-produktion") }) {
                org.jetbrains.compose.web.dom.Text("Kotlin (Produktion)")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Kotlin ist seit 2020 meine bevorzugte Programmiersprache. Was als Alternative zu Java für die Android-Entwicklung begann, wurde schnell zu meiner Standardwahl für alles – von Backend-Diensten bis hin zu Multiplattform-Projekten. Sogar die Website, die du gerade besuchst, wurde damit erstellt. Seit 2021 habe ich in jedem Projekt die Flexibilität und Prägnanz von Kotlin genutzt.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("stack") }) {
                org.jetbrains.compose.web.dom.Text("Stack")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.coroutines (Flow, etc.), RxJava (erste Wahl).")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("kotlinx.serialization, Gson (erste Wahl), Moshi.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Ktor, OkHttp, Retrofit, Fuel, Okio (erste Wahl).")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("RSocket")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/sdk") {
                        org.jetbrains.compose.web.dom.Text("gRPC")
                    }
                    org.jetbrains.compose.web.dom.Text(", Rest.")
                }
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("android") }) {
                org.jetbrains.compose.web.dom.Text("Android")
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("architekturen") }) {
                org.jetbrains.compose.web.dom.Text("Architekturen")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("MVP")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("MVI")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/contacts-app") {
                        org.jetbrains.compose.web.dom.Text("MVVM")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("design") }) {
                org.jetbrains.compose.web.dom.Text("Design")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("XML (mit ViewBinding / DataBinding)")
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Jetpack Compose")
                    }
                    org.jetbrains.compose.web.dom.Text(" (Material2 + Material3) (bevorzugt)")
                    org.jetbrains.compose.web.dom.Ul {
                        org.jetbrains.compose.web.dom.Li {
                            org.jetbrains.compose.web.dom.Text("Accompanist-Bibliotheken")
                        }
                    }
                }
            }
            org.jetbrains.compose.web.dom.H4(attrs = { id("bibliotheken") }) {
                org.jetbrains.compose.web.dom.Text("Bibliotheken")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Room, SQLDelight")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Picasso, Glide, Coil")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Firebase")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Admob")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Android Lifecycle (ViewModel, etc.)")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Eine Übersicht über einige verwendete Bibliotheken findest du ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/simple-vocabulary") {
                    org.jetbrains.compose.web.dom.Text("hier")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("multiplattform") }) {
                org.jetbrains.compose.web.dom.Text("Multiplattform")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Compose Multiplatform")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("SQLDelight")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/app") {
                        org.jetbrains.compose.web.dom.Text("Decompose")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("java") }) {
                org.jetbrains.compose.web.dom.Text("Java")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Java ist ein wichtiger Bestandteil meiner Grundlagen, auch wenn ich es heute nicht mehr so oft benutze. Mein letztes großes Projekt war für eine Universität – ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/y9vad9/restaurant-coursework") {
                    org.jetbrains.compose.web.dom.Text("Telegram-Bot")
                }
                org.jetbrains.compose.web.dom.Text(". Obwohl Kotlin besser zu meinen Bedürfnissen passt, verfolge ich weiterhin die Java-Updates – es ist wie ein alter Freund, den man nicht oft sieht, aber nie aus den Augen verliert.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Die Erfahrung ist dieselbe wie mit Kotlin. Ich habe es früher verwendet.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("php") }) {
                org.jetbrains.compose.web.dom.Text("PHP")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Meine erste Programmiersprache. PHP war mein Einstieg in die Welt des Programmierens und weckte meine Begeisterung für das Erstellen von Software. Auch wenn ich mittlerweile andere Sprachen bevorzuge, schätze ich die Weiterentwicklung von PHP. Es hat mein erstes großes Projekt, ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(", ermöglicht und mir wertvolle Erfahrungen in Optimierung und Skalierbarkeit vermittelt.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Stack:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Laravel")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Json, XML")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("usw.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Meistens habe ich reines PHP verwendet, um nicht zu komplexe CRUD-Anwendungen zu schreiben.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("python") }) {
                org.jetbrains.compose.web.dom.Text("Python")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ich habe nur an der Oberfläche von Python gekratzt – einige Skripte geschrieben, kleine Hobbyprojekte erstellt und einem Kommilitonen beim Lernen geholfen. Fun Fact: Dieser Freund schwört jetzt auf Kotlin – dank mir.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("typescript") }) {
                org.jetbrains.compose.web.dom.Text("TypeScript")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Eine neuere Ergänzung meines Werkzeugkastens. TypeScript kam während des Projekts ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates/rrpc-ts") {
                    org.jetbrains.compose.web.dom.Text("rrpc")
                }
                org.jetbrains.compose.web.dom.Text(" ins Spiel, das der Generierung von RPC-Diensten mit RSocket dient. Auch wenn ich kein TypeScript-Guru bin, fasziniert mich sein starkes Typsystem und Features wie Union Types.")
            }
        }
    }
}
