package com.y9vad9.pages.de.about_me

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/de/about-me/project-details")
@Composable
fun ProjectsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("de/about-me/projects.md", mapOf("title" to listOf("Meine Projekte")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("meine-projekte") }) {
                org.jetbrains.compose.web.dom.Text("Meine Projekte")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hier möchte ich einige Details und die Geschichte meiner Projekte vorstellen, jedoch nicht die bestehende Seite mit meinen Projekten ersetzen:")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("/de/archive/sketchcode") {
                    org.jetbrains.compose.web.dom.Text("Sketchcode: Mein Erstes Öffentliches Projekt")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Im Jahr 2018 habe ich ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" erstellt, ein soziales Netzwerk für Entwickler, die ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchware")
                }
                org.jetbrains.compose.web.dom.Text(" nutzen – einen No-Code-Android-App-Builder. Es ging nicht nur darum, Projekte zu teilen; es wurde zu einem Zentrum für Tutorials, UI-Komponenten und Zusammenarbeit. Auf seinem Höhepunkt unterstützte ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("Sketchcode")
                }
                org.jetbrains.compose.web.dom.Text(" 3.000 aktive Nutzer mit einem minimalen Budget. Der Betrieb lehrte mich nicht nur das Programmieren, sondern auch Widerstandsfähigkeit und Einfallsreichtum.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-2") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("../archive/task_manager") {
                    org.jetbrains.compose.web.dom.Text("Task Manager")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Der Task Manager war mein zweites Projekt und eine mobile Anwendung, die Benutzern half, die Startanwendungen ihres Geräts, aktive Prozesse und den RAM-Verbrauch zu verwalten. Mit seiner benutzerfreundlichen Oberfläche und leistungsstarken Funktionen bot er eine effiziente Möglichkeit, die Leistung des Geräts zu optimieren.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-3") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://course.y9vad9.com/en") {
                    org.jetbrains.compose.web.dom.Text("Kotlin Kurs")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Als ich mein Studium begann, bemerkte ich, dass viele meiner Kommilitonen Schwierigkeiten hatten, in die Softwareentwicklung einzusteigen. Deshalb erstellte ich einen Kotlin-Kurs, zunächst als PDF für meine Gruppe, dann als größeres Projekt. Während er derzeit pausiert, hoffe ich, ihn eines Tages als YouTube-Serie wiederzubeleben. Zusätzlich hatte ich einen Gradle-Kurs, den ich schließlich durch Artikel auf dev.to ersetzte:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf") {
                        org.jetbrains.compose.web.dom.Text("Gradle: Von Anfänger zu soliden Grundlagen")
                    }
                }
                org.jetbrains.compose.web.dom.Li {
                    com.varabyte.kobweb.silk.components.navigation.Link("https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl") {
                        org.jetbrains.compose.web.dom.Text("Das richtige Gleichgewicht in der Gradle-Abhängigkeitsstrategie finden")
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("-4") }) {
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/timemates") {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("TimeMate")
                }
                org.jetbrains.compose.web.dom.Text(" hebt die Pomodoro-Technik auf die nächste Stufe, indem es Benutzern ermöglicht, Timer mit Freunden oder Teammitgliedern für gemeinsame Konzentration zu teilen. Es ist eines dieser Projekte, an denen ich gerne arbeite, aber nie ganz abschließe – doch ich kann es einfach nicht loslassen.")
            }
        }
    }
}
