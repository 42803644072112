package com.y9vad9.pages.uk.archive.sketchcode

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/uk/archive/sketchcode")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("uk/archive/sketchcode/index.md", mapOf("title" to listOf("Sketchcode")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("sketchcode") }) {
                org.jetbrains.compose.web.dom.Text("Sketchcode")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("У 2018 році я створив ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("Sketchcode")
                    }
                    org.jetbrains.compose.web.dom.Text(", соціальну мережу для розробників, які використовують ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://sketchware-docs.vercel.app/") {
                        org.jetbrains.compose.web.dom.Text("Sketchware")
                    }
                    org.jetbrains.compose.web.dom.Text(" — конструктор без коду для Android. Це було не просто для обміну проектами; проект став центром для туторіалів, UI-компонентів та співпраці. На піку ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("Sketchcode")
                    }
                    org.jetbrains.compose.web.dom.Text(" підтримував 3 000 активних користувачів на обмеженому бюджеті. Його запуск навчив мене не лише кодуванню — він навчив мене витривалості та винахідливості.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("скриншоти") }) {
                org.jetbrains.compose.web.dom.Text("Скриншоти")
            }
            org.jetbrains.compose.web.dom.Table {
                org.jetbrains.compose.web.dom.Thead {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                    }
                }
                org.jetbrains.compose.web.dom.Tbody {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/posts-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/posts-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/comments-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/comments-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/more-page.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/more-page.png", "")
                            }
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/project-manager.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/project-manager.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/tutorials-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/tutorials-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/code-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/code-screen.png", "")
                            }
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/chat-screen.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/chat-screen.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/pub-project-screen.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/pub-project-screen.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/project-market.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/project-market.jpeg", "")
                            }
                        }
                    }
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Примітка: на жаль, у мене немає скріншотів для більшої частини (включаючи зміни в додатку), тому я надаю лише ті Які мені вдалося знайти.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("історія") }) {
                org.jetbrains.compose.web.dom.Text("Історія")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ця секція присвячена історії Sketchcode, моєму першому проекту, який розпочався як додаток для Sketchware, створений за допомогою самого Sketchware. Це був проект, який я робив із великою пристрастю, і він швидко вийшов за межі no-code розробки, зрештою перейшовши на Android Studio і Java, що стало початком мого шляху в програмуванні.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("перші-кроки-глобальна-стрічка-та-кастомні-блоки") }) {
                org.jetbrains.compose.web.dom.Text("Перші кроки: Глобальна стрічка та кастомні блоки")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Перша версія Sketchcode представила кілька основних функцій, які були корисні для спільноти Sketchware:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Глобальна стрічка, де користувачі могли відкривати та ділитися контентом.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Кастомні блоки, які користувачі Sketchware могли легко інтегрувати у свої проекти.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Кодові фрагменти, що дозволяли більш досвідченим користувачам вставляти сирий Java код через спеціальний блок.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ці функції стали основою Sketchcode і зробили його незамінним інструментом для користувачів Sketchware, які хотіли розширити свої можливості.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("друга-велика-версія-спільнота-та-навчальні-матеріали") }) {
                org.jetbrains.compose.web.dom.Text("Друга велика версія: Спільнота та навчальні матеріали")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Друга велика версія підняла додаток на новий рівень, давши змогу спільноті ділитися своїми власними творіннями:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Функція обміну кастомними блоками та фрагментами коду, що спрощувала співпрацю користувачів.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Новий розділ з туторіалами, в якому я публікував свої посібники для новачків.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Конструктор UI для туторіалів, щоб користувачі могли створювати та ділитися власними навчальними матеріалами.")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Примітка: На жаль, у мене немає скріншотів з цієї версії, тому я надаю єдине, яке мені вдалося знайти.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ця версія трансформувала Sketchcode у простір для співпраці, сприяючи навчанню та творчості.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("третя-велика-версія-проекти-та-приватні-резервні-копії") }) {
                org.jetbrains.compose.web.dom.Text("Третя велика версія: Проекти та приватні резервні копії")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Третя велика версія додала ключові нові функції для підтримки та демонстрації користувацьких проектів:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Функція обміну публікаціями та проектами Sketchware з іншими користувачами.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Приватні проекти для тих, хто хотів зберігати свою роботу в безпечному вигляді.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Це оновлення закріпило Sketchcode як хаб для користувачів Sketchware, щоб з’єднуватися, співпрацювати та зберігати свою роботу.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("четверта-велика-версія-великі-зміни-в-інтерфейсі-та-функції-взаємодії") }) {
                org.jetbrains.compose.web.dom.Text("Четверта велика версія: Великі зміни в інтерфейсі та функції взаємодії")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Четверта велика версія була присвячена покращенню взаємодії з користувачем та оптимізації досвіду:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Оновлений інтерфейс, що надавав додатку сучасний вигляд та покращену зручність використання.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Покращення проектного ринку, включаючи коментарі, лайки та інші соціальні функції.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Підтримка функції проектів була великою проблемою. З понад 60 ГБ серверного сховища та без монетизації, я витратив безліч годин на оптимізацію структури файлів та стиснення обмінених проектів, щоб забезпечити плавну роботу.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("пік-4-5-тисяч-користувачів") }) {
                org.jetbrains.compose.web.dom.Text("Пік: 4-5 тисяч користувачів")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("На піку популярності, Sketchcode мав 4 000–5 000 користувачів, генеруючи понад 10 мільйонів запитів на місяць. Це був цікавий, виснажливий і глибоко винагороджуючий період. Я часто працював всю ніч, захоплений процесом побудови та покращення чогось, що люди справді любили використовувати.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("остання-велика-функція-система-чату") }) {
                org.jetbrains.compose.web.dom.Text("Остання велика функція: Система чату")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Останньою великою функцією, яку я додав, була проста функція чату з підтримкою зображень та стікерів. Це дало користувачам можливість спілкуватися в реальному часі, ще більше посилюючи відчуття спільноти в додатку.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("роздуми") }) {
                org.jetbrains.compose.web.dom.Text("Роздуми")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Оглядаючись назад, Sketchcode був більше, ніж просто додаток. Це був мій вхід у програмування та проект, що змусив мене розвиватися способами, про які я навіть не міг уявити. Від скромних початків у Sketchware до створення надійної, багатофункціональної платформи — це залишається визначним моментом у моєму шляху як розробника.")
            }
        }
    }
}
