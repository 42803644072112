package com.y9vad9.localization

import com.y9vad9.types.Article
import com.y9vad9.types.Project

object UkrainianStrings : Strings {
    override val code: String = "uk"
    override val homeTitle: String = "Головна"
    override val projectsTitle: String = "Проекти"
    override val librariesTitle: String = "Бібліотеки"
    override val publicActivityTitle: String = "Публічна активність"
    override val blogTitle: String = "Блог"
    override val latestPostsTitle: String = "Останні пости"
    override val generalSummaryTitle: String = "Загальний підсумок"
    override val languagesISpeakTitle: String = "Мови, якими я володію"
    override val languagesISpeakDescription: String = """
          • ☕️ Англійська (B2+)
          • 🇩🇪 Німецька (B1)
          • 🇺🇦 Українська (C2)
          • 🏳️ Російська (C2)
    """.trimIndent()
    override val languageICodeWithTitle: String = "Мови програмування"
    override val languagesICodeWithDescription: String = "• Kotlin\n• Java\n• PHP\n• Python (Pet)\n• TS/JS (Pet)"
    override val developmentPlatformsTitle: String = "Платформи для розробки"
    override val developmentPlatformsDescription: String = "• Android\n• iOS\n• Web\n• JVM (Desktop, Backend)"
    override val myName: String = "Вадим Ярощук"
    override val myDescription: String = "Я програміст Kotlin з 6-річним досвідом, переважно в особистих проектах. Хоча мій професійний досвід обмежений, я набув практичних навичок, працюючи над різними додатками, зокрема з Kotlin Multiplatform та розробкою клієнт-сервер. Мені подобається вирішувати складні задачі, спрощувати код і ділитися тим, чому навчився, через викладання та написання статей."
    override val learnMore: String = "Дізнатися більше"
    override val latestProjectsTitle: String = "Останні проекти"
    override val projects: List<Project> = listOf(
        Project(
            name = "TimeMates",
            description = "Додаток для тайм-менеджменту та співпраці. За допомогою TimeMates ви можете досягти ідеального балансу між роботою та особистим життям, залишаючись продуктивним і ефективним. Функція Time-boxing дозволяє налаштувати таймер, що відповідає вашому розкладу, будь то робота над проектом або перерва. TimeMates — це не просто інструмент для керування часом, це зміна способу життя.",
            url = "https://github.com/timemates",
            imageUrl = "timemates-icon.png"
        ),
        Project(
            name = "Kotlin Course",
            description = "Курс Kotlin, який ідеально підходить для початківців. Доступний як українською, так і англійською мовами, розподілений на два треки — один для Gradle і один для Kotlin.",
            url = "https://github.com/y9vad9/kotlin-course",
            imageUrl = "kotlin-course-icon.png",
            roundImage = false,
        )
    )
    override val libraries: List<Project> = listOf(
        Project(
            name = "Brawl Stars API",
            description = "Ця бібліотека надає надійний та типобезпечний спосіб взаємодії з офіційними Brawl Stars та Brawlify API. Розроблена для розробників, яким необхідне надійне оброблення даних, вона пропонує типобезпечні типи для взаємодії, заздалегідь визначені обмеження та утиліти для покращення інтеграції та зменшення помилок.",
            url = "https://github.com/y9vad9/brawlstars-api",
            roundImage = false,
        ),
        Project(
            name = "rRPC",
            description = "rRPC — це фреймворк, який надає можливість експонувати ваш API як RPC сервіси за допомогою RSocket. Він спрощує створення служб, подібних до gRPC, з .proto файлів через генерацію коду. Фреймворк також надає основні компоненти для сервера та клієнта.",
            url = "https://github.com/timemates/rrpc-kotlin",
            roundImage = false,
        ),
        Project(
            name = "rsocket-kotlin-router",
            description = "rsocket-kotlin-router — це настроювана бібліотека, призначена для оптимізації та спрощення маршрутизації для RSocket Kotlin серверних додатків. Бібліотека пропонує типобезпечний DSL для обробки різних маршрутів, що є декларативною спрощеною альтернативою ручній маршрутизації з використанням довгих тернарних операторів або виснажливих операцій \"when\".",
            url = "https://github.com/y9vad9/rsocket-kotlin-router",
            roundImage = false,
        ),
        Project(
            name = "implier",
            description = "Бібліотека Kotlin Symbol Processor для створення Mutable, Immutable, Builders, DSL Builders з інтерфейсів та абстрактних класів з властивостями.",
            url = "https://github.com/y9vad9/implier",
            roundImage = false,
        ),
    )
    override val articles: List<Article> = listOf(
        Article(
            name = "Finding the Right Balance Between DDD, Clean and Hexagonal Architectures",
            description = "Стаття про поєднання та впровадження Domain-driven Design, Clean та Hexagonal архітектур. Доступно лише англійською.",
            url = "https://dev.to/y9vad9/digging-deep-to-find-the-right-balance-between-ddd-clean-and-hexagonal-architectures-4dnn",
            previewUrl = "previews/DDD-Hexagonal-Article-Preview.jpg"
        ),
        Article(
            name = "Finding the Right Balance in Gradle Dependency Strategy",
            description = "Ознайомтесь з різними підходами до управління залежностями в Gradle. Незалежно від того, чи ви новачок чи профі, тут є щось для вас. Доступно лише англійською.",
            url = "https://dev.to/y9vad9/finding-the-right-balance-in-gradle-dependency-strategy-4jdl",
            previewUrl = "previews/gradle-dependency-strategy.png",
        ),
        Article(
            name = "Gradle: from Newbie to Strong fundamentals",
            description = "Зануртесь у світ Gradle, де я розглядаю плагіни, залежності, репозиторії, структури проєктів та навіть багатомодульні проєкти (з їх особливостями!). Незалежно від того, чи ви новачок у Gradle, чи хочете зміцнити свої основи, ця стаття для вас! Доступно лише англійською.",
            url = "https://dev.to/y9vad9/gradle-from-newbie-to-strong-fundamentals-mdf",
            previewUrl = "previews/gradle-for-newbies.png",
        ),
        Article(
            name = "Kotlin: Coroutines are not just about concurrency",
            description = "Ознайомтесь з основами Kotlin Coroutines, від основ до детального розгляду їх роботи. Я також поділюсь прикладами, як їх можна використовувати не тільки для конкуруючих завдань. Доступно лише англійською.",
            url = "https://dev.to/y9vad9/coroutines-are-not-just-about-concurrency-4bfe",
            previewUrl = "previews/coroutines-beyond-concurrency.jpg",
        ),
        Article(
            name = "Extension Oriented Design in Kotlin",
            description = "Розкрийте потенціал Extension-Oriented Design у програмуванні. Дізнайтесь, як розширення функцій пропонують рішення для доступу до класів, обхід обмежень вбудованих функцій та організацію коду більш ефективно. Дізнайтесь про переваги цього підходу і покращіть ваш код сьогодні. Доступно лише англійською.",
            url = "https://dev.to/y9vad9/extension-oriented-design-3d41",
            previewUrl = "previews/EOD-Kotlin.jpg",
        )
    )
    override val checkAll: String = "Переглянути всі"
    override val exploreTitle: String = "Переглянути"
}
