package com.y9vad9.pages.en.archive.task_manager

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/en/archive/task_manager")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("en/archive/task_manager/index.md", mapOf("title" to listOf("Task Manager")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("task-manager") }) {
                org.jetbrains.compose.web.dom.Text("Task Manager")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Task Manager was a mobile application designed to help users manage their device's startup applications, active processes, and RAM usage. With its user-friendly interface and powerful functionality, it provided users with an efficient way to optimize their device's performance.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("features") }) {
                org.jetbrains.compose.web.dom.Text("Features")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Startup Application Management")
                    }
                    org.jetbrains.compose.web.dom.Text(": Enable or disable applications that start when the phone boots.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Process Management")
                    }
                    org.jetbrains.compose.web.dom.Text(": View and terminate running processes to free up system resources.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("RAM Cleaning")
                    }
                    org.jetbrains.compose.web.dom.Text(": Optimize memory usage for better performance.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("User-Friendly Interface")
                    }
                    org.jetbrains.compose.web.dom.Text(": Simple and intuitive UI for easy navigation.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("achievements") }) {
                org.jetbrains.compose.web.dom.Text("Achievements")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("70,000+ Downloads")
                    }
                    org.jetbrains.compose.web.dom.Text(": The app was downloaded more than 70K times.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("7,000 Monthly Active Users")
                    }
                    org.jetbrains.compose.web.dom.Text(": At its peak, the application had 7K active users per month.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("screenshots") }) {
                org.jetbrains.compose.web.dom.Text("Screenshots")
            }
            org.jetbrains.compose.web.dom.Table {
                org.jetbrains.compose.web.dom.Thead {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                    }
                }
                org.jetbrains.compose.web.dom.Tbody {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/task-manager/processes.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/task-manager/processes.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/task-manager/startup.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/task-manager/startup.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/task-manager/startup.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/task-manager/startup_management.jpeg", "")
                            }
                        }
                    }
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("conclusion") }) {
                org.jetbrains.compose.web.dom.Text("Conclusion")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Task Manager was a widely used tool for mobile optimization, helping users keep their devices running smoothly. While the project is no longer active, its impact and success remain notable.")
            }
        }
    }
}
