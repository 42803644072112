package com.y9vad9.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.VerticalDivider
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.y9vad9.LOCALE_KEY
import com.y9vad9.localization.Strings
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Span
import com.y9vad9.toSitePalette
import kotlinx.browser.localStorage
import kotlinx.browser.window

val FooterStyle = CssStyle.base {
    Modifier
        .backgroundColor(colorMode.toSitePalette().nearBackground)
        .padding(topBottom = 1.5.cssRem, leftRight = 10.percent)
        .fillMaxWidth()
}

@Composable
fun Footer(modifier: Modifier = Modifier) {
    Column(FooterStyle.toModifier().then(modifier), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
        Row(horizontalArrangement = Arrangement.spacedBy(1.cssRem), verticalAlignment = Alignment.CenterVertically) {
            LanguageChooser(false)
        }

        Span(Modifier.textAlign(TextAlign.Center).toAttrs()) {
            SpanText("${Strings.current.myName} © 2025")
        }
    }
}

@Composable
private fun LanguageChooser(isSideMenu: Boolean) {
    val ctx = rememberPageContext()
    val langRegex = Regex("ru/|en/|de/|uk/")
    val chosenLanguage: String = Strings.current.code.trim()

    SpanText(
        modifier = Modifier.onClick {
            localStorage.setItem(LOCALE_KEY, "en")
            if (window.location.pathname.contains(langRegex))
                ctx.router.navigateTo(window.location.pathname.replace(langRegex, "en/"))
            window.location.reload()
        }.cursor(Cursor.Pointer).let {
            if (chosenLanguage == "en") {
                it.color(Colors.Purple)
            } else it
        },
        text = if(isSideMenu) "English" else "En"
    )

    if (!isSideMenu)
        VerticalDivider(Modifier.height(1.cssRem))

    SpanText(
        modifier = Modifier.onClick {
            localStorage.setItem(LOCALE_KEY, "de")
            if (window.location.pathname.contains(langRegex))
                ctx.router.navigateTo(window.location.pathname.replace(langRegex, "de/"))
            window.location.reload()
        }.cursor(Cursor.Pointer).let {
            if (chosenLanguage == "de") {
                it.color(Colors.Purple)
            } else it
        },
        text = if(isSideMenu) "Deutsch" else "De"
    )

    if (!isSideMenu)
        VerticalDivider(Modifier.height(1.cssRem))

    SpanText(
        modifier = Modifier.onClick {
            localStorage.setItem(LOCALE_KEY, "uk")
            if (window.location.pathname.contains(langRegex))
                ctx.router.navigateTo(window.location.pathname.replace(langRegex, "uk/"))
            window.location.reload()
        }.cursor(Cursor.Pointer).let {
            if (chosenLanguage == "uk") {
                it.color(Colors.Purple)
            } else it
        },
        text = if(isSideMenu) "Українська" else "Укр"
    )

    if (!isSideMenu)
        VerticalDivider(Modifier.height(1.cssRem))

    SpanText(
        modifier = Modifier.onClick {
            localStorage.setItem(LOCALE_KEY, "ru")
            if (window.location.pathname.contains(langRegex))
                ctx.router.navigateTo(window.location.pathname.replace(langRegex, "ru/"))
            window.location.reload()
        }.cursor(Cursor.Pointer).let {
            if (chosenLanguage == "ru") {
                it.color(Colors.Purple)
            } else it
        },
        text = if(isSideMenu) "Русский" else "Рус"
    )
}
