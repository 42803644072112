package com.y9vad9.pages.ru.archive.sketchcode

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/ru/archive/sketchcode")
@Composable
fun IndexPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("ru/archive/sketchcode/index.md", mapOf("title" to listOf("Sketchcode")))) {
        com.y9vad9.components.layouts.MarkdownLayout {
            org.jetbrains.compose.web.dom.H1(attrs = { id("sketchcode") }) {
                org.jetbrains.compose.web.dom.Text("Sketchcode")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("В 2018 году я создал ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("Sketchcode")
                    }
                    org.jetbrains.compose.web.dom.Text(", социальную сеть для разработчиков, использующих ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://sketchware-docs.vercel.app/") {
                        org.jetbrains.compose.web.dom.Text("Sketchware")
                    }
                    org.jetbrains.compose.web.dom.Text(" — инструмент для создания Android-приложений без программирования. Это было не просто место для обмена проектами; ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("Sketchcode")
                    }
                    org.jetbrains.compose.web.dom.Text(" стал платформой для обучения, обмена UI-компонентами и сотрудничества. ? На пике своего развития ")
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("Sketchcode")
                    }
                    org.jetbrains.compose.web.dom.Text(" поддерживал 3,000 активных пользователей при минимальном бюджете. Управление этим проектом научило меня не только программированию, но и стойкости, а также находчивости.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("скриншоты") }) {
                org.jetbrains.compose.web.dom.Text("Скриншоты")
            }
            org.jetbrains.compose.web.dom.Table {
                org.jetbrains.compose.web.dom.Thead {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                        org.jetbrains.compose.web.dom.Th()
                    }
                }
                org.jetbrains.compose.web.dom.Tbody {
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/posts-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/posts-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/comments-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/comments-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/more-page.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/more-page.png", "")
                            }
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/project-manager.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/project-manager.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/tutorials-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/tutorials-screen.png", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/code-screen.png") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/code-screen.png", "")
                            }
                        }
                    }
                    org.jetbrains.compose.web.dom.Tr {
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/chat-screen.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/chat-screen.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/pub-project-screen.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/pub-project-screen.jpeg", "")
                            }
                        }
                        org.jetbrains.compose.web.dom.Td {
                            com.varabyte.kobweb.silk.components.navigation.Link("/projects/sketchcode/images/project-market.jpeg") {
                                com.varabyte.kobweb.silk.components.graphics.Image("/projects/sketchcode/images/project-market.jpeg", "")
                            }
                        }
                    }
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Примечание: многие скиншоты, к сожалению, у меня отсутствуют с того времени, потому показываю только то, что нашел.")
                }
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("история") }) {
                org.jetbrains.compose.web.dom.Text("История")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Эта секция посвящена истории Sketchcode, моего самого первого проекта, который начинался как приложение для Sketchware, созданное с использованием самого Sketchware. Это был проект, созданный с любовью, который вышел далеко за рамки возможностей no-code разработки и в итоге перешел в Android Studio и Java, ознаменовав начало моего пути в программировании.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("первые-шаги-глобальная-лента-и-кастомные-блоки") }) {
                org.jetbrains.compose.web.dom.Text("Первые шаги: Глобальная лента и кастомные блоки")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Первый релиз Sketchcode представил несколько ключевых функций, предназначенных для сообщества Sketchware:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Глобальная лента, где пользователи могли находить и делиться контентом.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Кастомные блоки, которые пользователи Sketchware могли легко интегрировать в свои проекты.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Фрагменты кода, позволяющие более продвинутым пользователям включать чистый Java-код через специальный блок.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Эти функции стали основой Sketchcode и сделали его незаменимым инструментом для пользователей Sketchware, стремящихся расширить свои возможности.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("второе-крупное-обновление-сообщество-и-учебные-материалы") }) {
                org.jetbrains.compose.web.dom.Text("Второе крупное обновление: Сообщество и учебные материалы")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Второй крупный релиз поднял приложение на новый уровень, предоставив сообществу возможность делиться своими кастомными разработками:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Функция обмена кастомными блоками и фрагментами кода, упрощающая сотрудничество пользователей.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Новый раздел с учебными материалами, содержащий написанные мной руководства для начинающих.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Конструктор UI для учебных материалов, чтобы пользователи могли создавать и делиться собственными инструкциями.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Этот релиз превратил Sketchcode в пространство для сотрудничества, обучения и творчества.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("третье-крупное-обновление-проекты-и-приватные-бэкапы") }) {
                org.jetbrains.compose.web.dom.Text("Третье крупное обновление: Проекты и приватные бэкапы")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Третий крупный релиз добавил ключевые функции для поддержки и демонстрации пользовательских проектов:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Возможность делиться своими постами и проектами Sketchware с сообществом.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Функция приватных проектов для тех, кто хотел создать надежную резервную копию своей работы.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Это обновление закрепило Sketchcode как центр для пользователей Sketchware, где они могли взаимодействовать, сотрудничать и хранить свои работы.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("четвертое-крупное-обновление-большие-изменения-ui-и-функции-взаимодействия") }) {
                org.jetbrains.compose.web.dom.Text("Четвертое крупное обновление: Большие изменения UI и функции взаимодействия")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Четвертый крупный релиз был посвящен улучшению взаимодействия с пользователями и оптимизации опыта:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Обновленный пользовательский интерфейс, придавший приложению современный вид и повысивший удобство.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("Улучшения в разделе \"Рынок проектов\", включая комментарии, лайки и другие социальные функции.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Поддержка функций проектов была значительным вызовом. С более чем 60 ГБ серверного хранилища и бесплатным подходом без монетизации я провел бесчисленные часы, оптимизируя структуру файлов и сжимая общие проекты, чтобы поддерживать стабильную работу сервиса.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("пик-популярности-4-5-тысяч-пользователей") }) {
                org.jetbrains.compose.web.dom.Text("Пик популярности: 4–5 тысяч пользователей")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("На пике популярности Sketchcode насчитывал 4–5 тысяч пользователей, генерировавших более 10 миллионов запросов в месяц. Это было захватывающее, изнурительное и невероятно вдохновляющее время. Я часто работал ночами, увлеченный процессом создания и улучшения того, что люди действительно любили использовать.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("последняя-крупная-функция-чат") }) {
                org.jetbrains.compose.web.dom.Text("Последняя крупная функция: Чат")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Последней крупной функцией, добавленной в Sketchcode, стал простой чат с поддержкой изображений и стикеров. Это дало пользователям возможность общаться в реальном времени, еще больше укрепляя чувство сообщества внутри приложения.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("размышления") }) {
                org.jetbrains.compose.web.dom.Text("Размышления")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Оглядываясь назад, Sketchcode был не просто приложением. Это был мой вход в мир программирования и проект, который заставил меня развиваться так, как я даже не мог себе представить. От скромного начала в Sketchware до создания мощной, насыщенной функциями платформы — это по-прежнему остается определяющим моментом в моем пути разработчика.")
            }
        }
    }
}
